import styled from "styled-components"
import { device } from "breakpoints"

export const LinkWrapper = styled.a`
  display: flex;
  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
  }

  @media ${device.desktop} {
    span {
      position: relative;
      font-size: 18px;
      ::before {
        position: absolute;
        content: "";
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: #fff;
        opacity: 0;
      }
    }

    :hover {
      span {
        ::before {
          opacity: 1;
        }
      }
    }
  }
`

export const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  background: ${(props) => props.theme.colors.orange};
  margin-right: 15px;
`
