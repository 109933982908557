import React from "react"
import { Box, MainColumn } from "ui"
import { Logos, Title, LogosContainer, LogoWrapper } from "./style"

const LogoGrid = ({ data }) => {
  return (
    <Box pt={[35, 95]} pb={[10, 40]}>
      <MainColumn>
        {data?.heading && <Title>{data?.heading}</Title>}
        <LogosContainer>
          {data?.images.map((item, index) => {
            return (
              <LogoWrapper key={index}>
                <Logos fluid={item?.image} />
              </LogoWrapper>
            )
          })}
        </LogosContainer>
      </MainColumn>
    </Box>
  )
}

export default LogoGrid
