import styled from "styled-components"
import { device } from "breakpoints"
import { Icon, MainColumn } from "ui"

export const SectionWrapper = styled.div`
  background: #003764;
  position: relative;
  overflow: hidden;
  
}

`

export const Body = styled.div`
  padding: 50px 0;


  @media ${device.tablet} {
    padding: 50px 25px;
  }
  
  @media ${device.large} {
    padding: 120px 0;
  }
  @media ${device.big} {
    padding: 195px 0;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
  }

  @media ${device.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }

  @media ${device.large} {
  }
`

export const FlexItem = styled.div`

  padding: ${(props) => (props.content ? "0 25px" : "0")};

  @media ${device.tablet} {
    flex: 1;
    padding: 0;
    width: 50%;
    display: ${props => props.content ? 'flex' : 'block'};
    justify-content: center;

    > .avenueImg {
      height: 100%;
    }
  }
`

export const Title = styled.h2`
  color: #fff;
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  padding-right: 50px;
  margin-bottom: 20px;

  @media ${device.desktop} {
    max-width: 460px;
    font-size: 60px;
    line-height: 64px;
    margin-bottom: 15px;
  }
`

export const Text = styled.p`
  color: #fff;
  max-width: 460px;
  margin-bottom: 30px;
  padding-right: 50px;

  @media ${device.desktop} {
    padding-right: 0;
  }
`

export const LeafIcon = styled(Icon)`
  position: absolute;
  top: 20px;
  right: -225px;

  @media ${device.desktop} {
    right: unset;
    top: -290px;
    left: -215px;
  }
`
