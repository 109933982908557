import React from "react"
import Layout from "components/layout"
import { graphql } from 'gatsby'
import SEO from "components/seo"
import { Home } from "screens/Home"

const home = ({ data }) => {
  const { title, headerSettings, featuredImage, seo } = data.page
  const pageTitle = headerSettings?.customPageTitle || title

  return (
    <Layout variant="home" pageContext={data.page}>
      <SEO
					title={seo.title}
					image={featuredImage?.node?.sourceUrl}
					description={seo.metaDesc}
				/>
      <Home data={data.page} />
    </Layout>
  )
}

export default home

export const pageQuery = graphql`
  query($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      headerSettings {
        customPageTitle
        hdDescription
      }
      seo {
				title
				metaDesc
			}
      featuredImage {
        node {
          sourceUrl
          srcSet
          mediaDetails {
            height
            width
          }
        }
      }

      homePageSections {
        featuredItems {
          title
          excerpt
          link {
            ... on WpPage {
              uri
            }
          }
          linkText
        }
        ctaBlock {
          title
          excerpt
          pageLink {
            ... on WpPage {
              uri
            }
          }
          linkText
          image {
            sourceUrl
            srcSet
            mediaDetails {
              height
              width
            }
          }
        }
        logos {
          heading
          images {
            image {
              sourceUrl
              srcSet
            }
            link
          }
        }
        contactBlock {
          title
          excerpt
          pageLink {
            ... on WpPage {
              uri
            }
          }
          linkText
        }
      }
    }
  }
`
