import React from "react"
import { Button } from "ui"
import {
  SectionWrapper,
  ContentWrapper,
  FlexItem,
  Title,
  Text,
  LeafIcon,
} from "./style"

const ContactCTA = ({ data }) => {
  const { excerpt, linkText, pageLink, title } = data
  const { uri } = pageLink

  return (
    <SectionWrapper>
      <LeafIcon
        name="leaf"
        opacity={0.1}
        width={[422, null, 388]}
        height={[636, null, 584]}
      />
      <ContentWrapper>
        <FlexItem>
          <Title>{title}</Title>
          <Text>{excerpt}</Text>
          <Button
            to={uri}
            alternHover={true}
            padding={"16px 38px"}
            fontWeight={600}
            fontSize={18}
            lineHeight={"24px"}
            minWidth={"0px"}
            minHeight={"0px"}
          >
            {linkText}
          </Button>
        </FlexItem>
      </ContentWrapper>
    </SectionWrapper>
  )
}

export default ContactCTA
