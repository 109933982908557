import React, { useContext } from "react"
import { HomeTemplateContainer } from "./style"
import { PreviewContext } from "context/PreviewContext"
import { CTASection } from "ui/CTASection"
import { ContactCTA } from "ui/ContactCTA"
import { LogoGrid } from "../LogoGrid"
import OverlayCards from "./OverlayCards"

const Home = ({ data }) => {
  const { homePageSections } = data

  const { previewData } = useContext(PreviewContext)
  const featuredItems = homePageSections?.featuredItems

  return (
    <HomeTemplateContainer>
      <OverlayCards data={featuredItems} />
      {homePageSections?.ctaBlock && (
        <CTASection data={homePageSections.ctaBlock} />
      )}
      <LogoGrid data={data.homePageSections?.logos} />
      {homePageSections?.contactBlock && (
        <ContactCTA data={homePageSections.contactBlock} />
      )}
    </HomeTemplateContainer>
  )
}

export default Home
