import styled from "styled-components"
import { device } from "breakpoints"
import AvenueImg from "ui/AvenueImg"
import { Box } from "ui"

export const LogosContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px 0;
  // border: 1px solid red;

  @media ${device.desktop} {
    margin: 60px 0 50px;
  }

  > * {
    display: block;
    width: 93px;
    margin-right: 8px;
    margin-bottom: 8px;
    flex-shrink: 0;

    @media ${device.tablet} {
      width: 106px;
      margin-right: 16px;
      margin-bottom: 16px;
    }

    @media ${device.desktop} {
      width: 151px;
    }
  }
`

export const Title = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 117%;
  color: ${(props) => props.theme.colors.blue};
  text-align: center;
  margin: 0 30px;

  @media ${device.desktop} {
    font-size: 32px;
    line-height: 112%;
    letter-spacing: 0.01em;
  }
`
export const Logos = styled(AvenueImg)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

export const LogoWrapper = styled(Box)`
  justify-self: center;
  align-self: center;
  aspect-ratio: 2.5;
  position: relative;
`
