import React from "react"
import { CarrotLink } from "../CarrotLink"
import {
  SectionWrapper,
  ContentWrapper,
  FlexItem,
  Title,
  Text,
  LeafIcon,
  Body
} from "./style"

import AvenueImg from "ui/AvenueImg"

const CTASection = ({ data }) => {
  const { excerpt, linkText, pageLink, title, image } = data
  const { uri } = pageLink

  return (
    <SectionWrapper>
      <LeafIcon
        name="leaf"
        opacity={0.1}
        width={[422, null, 676]}
        height={[636, null, 1018]}
      />
      <ContentWrapper>

        <FlexItem content>
          <Body>
            <Title>{title}</Title>
            <Text>{excerpt}</Text>
            <CarrotLink data={{ linkText, uri }} />
          </Body>
        </FlexItem>
        <FlexItem>

        <AvenueImg fluid={{ ...image, sizes: "100vw", aspectRatio: 1.704444444 }} />
       
        </FlexItem>
      </ContentWrapper>
    </SectionWrapper>
  )
}

export default CTASection
