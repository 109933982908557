import styled from "styled-components"
import { device } from "breakpoints"
import { Icon, MainColumn } from "ui"

export const SectionWrapper = styled.div`
  background: ${props => props.theme.colors.teal};
  padding: 46px 0;
  position: relative;
  overflow: hidden;
  
  @media ${device.desktop} {
    padding: 95px 0;
  }

`

export const ContentWrapper = styled(MainColumn)`
  display: flex;
  flex-direction: column;

  @media ${device.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`

export const FlexItem = styled.div`
  @media ${device.desktop} {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export const Title = styled.h2`
  color: #fff;
  font-weight: bold;
  margin-bottom: 18px;

  @media ${device.desktop} {
    max-width: 460px;
    margin-bottom: 0;
  }
`

export const Text = styled.p`
  color: #fff;
  max-width: 460px;
  margin-bottom: 30px;
  padding-right: 50px;

  @media ${device.desktop} {
    margin-bottom: 0px;
    padding-right: 25px;
    padding-left: 25px;
  }

  @media ${device.large} {
    max-width: 460px;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
`

export const LeafIcon = styled(Icon)`
    z-index: 0;
    position: absolute;
    top: 20px;
    right: -225px;

    @media ${device.desktop} {
        right: -200px;
        top: -170px;
    }
`
