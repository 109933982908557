import React from "react"

import { Box, Text, Icon, MainColumn } from "ui"
import { Grid, Row, Col } from "ui/Grid"

import AvenueLink from "ui/AvenueLink"
import styled from "styled-components"

export const OvelaryText = styled(Text)`
  @media screen and (-ms-high-contrast: none) {
    max-width: 270px;
  }
`

export const Link = styled(AvenueLink)`
  margin-top: auto;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  p {
    position: relative;
    ::before {
      content: "";
      position: absolute;
      left: 0;
      opacity: 0;
      height: 2px;
      width: 100%;
      bottom: -6px;
      transition: 200ms;
      background: white;
    }
  }
  :hover {
    p::before {
      opacity: 1;
      bottom: -3px;
    }
  }
`
const Card = ({ title, excerpt, link, linkText, bg }) => {
  return (
    <Box
      dir="row"
      bg={bg}
      ai={["center", null, "flex-start"]}
      jc="space-between"
      p={["11px 15px", null, "45px 50px 41px 50px"]}
      borderRadius="2px"
      position="relative"
      height={[null, "100%"]}
    >
      <Box height={"100%"}>
        <Text
          as="h3"
          fontSize={[20, null, 32]}
          color="white"
          mb={[null, null, 24]}
        >
          {title}
        </Text>
        <OvelaryText
          color="white"
          display={["none", null, "block"]}
          fontWeight={300}
          letterSpacing={0.5}
          mb={60}
        >
          {excerpt}
        </OvelaryText>

        <Link to={link.uri}>
          <Text
            color="white"
            fw={500}
            mt="auto"
            display={["none", null, "inline"]}
          >
            {linkText}
          </Text>
        </Link>
      </Box>

      <Icon
        name="arrow_right"
        color="white"
        width={9}
        height={16}
        display={[null, null, "none"]}
        mr={"5px"}
      />
    </Box>
  )
}

const OverlayCards = ({ data }) => {
  return (
    <Box
      pb={[30, null, 100]}
      zIndex={5}
      position="relative"
      mt={[null, null, -174]}
    >
      <MainColumn>
        <Grid>
          <Row>
            {data.map((item, index) => {
              return (
                <Col xs={12} sm={4} key={index}>
                  <Card
                    {...item}
                    bg={
                      index === 0
                        ? "#00A0A1"
                        : index === 1
                        ? "#163C67"
                        : index === 2
                        ? "#DC5F1F"
                        : "#00A0A1"
                    }
                  />
                </Col>
              )
            })}
          </Row>
        </Grid>
      </MainColumn>
    </Box>
  )
}

export default OverlayCards
