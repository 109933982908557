import React from "react"
import { LinkWrapper, IconButton } from "./style"
import { Icon } from "ui"

const CarrotLink = ({ data }) => {
  const { linkText, uri } = data
  return (
    <LinkWrapper href={uri}>
      <IconButton>
        <Icon name="bold_arrow_right" color="#fff" width={7} height={12} />
      </IconButton>
      <span>{linkText}</span>
    </LinkWrapper>
  )
}

export default CarrotLink
